
<!-- Generic vague message where we don't know the organization. Should not happen? -->
<ng-container *ngIf="!organization">
  Contact Learnswell support
</ng-container>

<ng-container *ngIf="organization">

  <ng-container [ngSwitch]="organization">


    <a
      *ngSwitchCase="'nbcrna'"
      href="https://cpcepractice-support.selfstudy.plus/portal/en/newticket?departmentId=187900000000554042&layoutId=187900000000556226"
      class="me-2">Contact Support
    </a>

    <ng-container *ngSwitchCase="'silkoak'">Message us on Facebook</ng-container>
    <ng-container *ngSwitchCase="'silkoak-bn'">Message us on Facebook</ng-container>

    <ng-container *ngSwitchCase="'abfas'">

      <p>
        Learnswell Support is unable to provide specific information about certification or program requirement expectations,
        including MDT p-value and other program specifics. For questions related to the above or other questions regarding the
        LEAD platform outside of technical support, please reach out to <a href="mailto:lead&#64;abfas.org">lead&#64;abfas.org</a> for further assistance.
      </p>

      <!-- will likely stay with ABFAS for now
      <p>
        For technical issues, contact Learnswell support by <a href="''">opening a support ticket</a>
      </p>
      -->

    </ng-container>

    <ng-container *ngSwitchCase="'abpm'">
      <p>Please reach out to <a href="mailto:abpm&#64;theabpm.org">abpm&#64;theabpm.org</a> for further assistance.
    </ng-container>

    <ng-container *ngSwitchDefault>
      <!-- TODO: We need to have a default way to open tickets for all customers, so we don't need separate zoho tickets for each.
           Will be addressed upon launching next customer that is not yet already in zoho.
       -->
      Contact Learnswell Support
    </ng-container>

  </ng-container>

</ng-container>


